<template>
  <div class="outLib">
    <div class="top-box">
      <div class="title-box flex-start-nowrap">
        <div class="title-text flex-space-between relative-box">
          <div>实时数据</div>
          <div>
            <icon-font class="icon-help" type="icon-bangzhu1"/>
            <div class="absolute-box">
              <div class="triangle"></div>
              <div class="font-help-new">展示的今日截止到该时刻的准实时数据,每隔15秒更 新一次。</div>
              <div class="flex-start-nowrap other-out0" v-for="(help,h) in helpTopList" :key = 'h'>
                <div class="font-help width-init-1">{{ help.name }}</div>
                <div class="font-help-new width-init-2">{{ help.annotation }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="selector-box">
          <!-- <el-select v-model="value" placeholder="请选择" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
      </div>
      <div class="display-card flex-space-around">
        <div :class="borderColor(billTotalList.firstList.type)">
          <div class="display-card-item">
            <div class="card-item-title flex-space-around">
              <!-- <div class="title-icon">
                <icon-font class="icon-color" :type="billTotalList.firstList.icon" :class="iconColor(billTotalList.firstList.type)" />
              </div> -->
              <div class="title-text">{{ billTotalList.firstList.totalName }}</div>
            </div>
            <div class="text-content">
              <!-- <span class="number" @mouseenter="enters(billTotalList.firstList)">{{ billTotalList.firstList.total == null?'0':getVal(billTotalList.firstList.total) }}</span> -->
              <span class="number" @mouseenter="enters(billTotalList.firstList)">{{ billTotalList.firstList.total == null?'0':billTotalList.firstList.total }}</span>
              <!-- <span class="unit">{{ billTotalList.firstList.unit }}</span> -->
            </div>
            <div class="align-items-center">
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.firstList.firstName }}</span>
                <span class="unit">{{ billTotalList.firstList.firstName == null?'':billTotalList.firstList.first == null?0:billTotalList.firstList.first }}</span>
                <!-- <span class="unit">{{ billTotalList.firstList.firstName == null?'':billTotalList.firstList.first == null?0:getVal(billTotalList.firstList.first)+getUnit(billTotalList.firstList.first) }}</span> -->
              </div>
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.firstList.secondtName }}</span>
                <span class="unit">{{ billTotalList.firstList.secondtName == null?'':billTotalList.firstList.second == null?0:billTotalList.firstList.second }}</span>
                <!-- <span class="unit">{{ billTotalList.firstList.secondtName == null?'':billTotalList.firstList.second == null?0:getVal(billTotalList.firstList.second)+getUnit(billTotalList.firstList.second) }}</span> -->
              </div>
            </div>
          </div>
          <div class="items flex-space-between">
            <img class="bottom-img" :src="bottomImg(billTotalList.firstList.type)" alt="">
          </div>
        </div>
        <div :class="borderColor(billTotalList.secondList.type)">
          <div class="display-card-item">
            <div class="card-item-title flex-space-around">
              <!-- <div class="title-icon">
                <icon-font class="icon-color" :type="billTotalList.secondList.icon" :class="iconColor(billTotalList.secondList.type)" />
              </div> -->
              <div class="title-text">{{ billTotalList.secondList.totalName }}</div>
            </div>
            <div class="text-content">
              <!-- <span class="number">{{ billTotalList.secondList.total == null?'0':getVal(billTotalList.secondList.total) }}</span> -->
              <span class="number">{{ billTotalList.secondList.total == null?'0':billTotalList.secondList.total }}</span>
              <!-- <span class="unit">{{ billTotalList.secondList.unit }}</span> -->
            </div>
            <div class="align-items-center">
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.secondList.firstName }}</span>
                <span class="unit">{{ billTotalList.secondList.firstName == null?'':billTotalList.secondList.first == null?0:billTotalList.secondList.first }}</span>
                <!-- <span class="unit">{{ billTotalList.secondList.firstName == null?'':billTotalList.secondList.first == null?0:getVal(billTotalList.secondList.first)+getUnit(billTotalList.secondList.first) }}</span> -->
              </div>
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.secondList.secondtName }}</span>
                <span class="unit">{{ billTotalList.secondList.secondtName == null?'':billTotalList.secondList.second == null?0:billTotalList.secondList.second }}</span>
                <!-- <span class="unit">{{ billTotalList.secondList.secondtName == null?'':billTotalList.secondList.second == null?0:getVal(billTotalList.secondList.second)+getUnit(billTotalList.secondList.second) }}</span> -->
              </div>
            </div>
          </div>
          <div class="items flex-space-between">
            <img class="bottom-img" :src="bottomImg(billTotalList.secondList.type)" alt="">
          </div>
        </div>
        <div :class="borderColor(billTotalList.thirdList.type)">
          <div class="display-card-item">
            <div class="card-item-title flex-space-around">
              <!-- <div class="title-icon-green">
                <icon-font class="icon-color" :type="billTotalList.thirdList.icon" :class="iconColor(billTotalList.thirdList.type)" />
              </div> -->
              <div class="title-text">{{ billTotalList.thirdList.totalName }}</div>
            </div>
            <div class="text-content">
              <span class="number">{{ billTotalList.thirdList.total == null?'0':billTotalList.thirdList.total }}</span>
              <!-- <span class="unit">{{ billTotalList.thirdList.unit }}</span> -->
            </div>
            <div class="align-items-center">
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.thirdList.firstName }}</span>
                <span class="unit">{{ billTotalList.thirdList.firstName == null?'':billTotalList.thirdList.first == null?0:getVal(billTotalList.thirdList.first) }}</span>
              </div>
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.thirdList.secondtName }}</span>
                <span class="unit">{{ billTotalList.thirdList.secondtName == null?'':billTotalList.thirdList.second == null?0:getVal(billTotalList.thirdList.second) }}</span>
              </div>
            </div>
          </div>
          <div class="items flex-space-between">
            <img class="bottom-img" :src="bottomImg(billTotalList.thirdList.type)" alt="">
          </div>
        </div>
        <div :class="borderColor(billTotalList.fourthList.type)">
          <div class="display-card-item">
            <div class="card-item-title flex-space-around">
              <!-- <div class="title-icon">
                <icon-font class="icon-color" :type="billTotalList.fourthList.icon" :class="iconColor(billTotalList.fourthList.type)" />
              </div> -->
              <div class="title-text">{{ billTotalList.fourthList.totalName }}</div>
            </div>
            <div class="text-content">
              <span class="number">{{ billTotalList.fourthList.total == null?'0':billTotalList.fourthList.total }}</span>
              <!-- <span class="unit">{{ billTotalList.fourthList.unit }}</span> -->
            </div>
            <div class="align-items-center">
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.fourthList.firstName }}</span>
                <span class="unit">{{ billTotalList.fourthList.firstName == null?'':billTotalList.fourthList.first == null?0:billTotalList.fourthList.first }}</span>
                <!-- <span class="unit">{{ billTotalList.fourthList.firstName == null?'':billTotalList.fourthList.first == null?0:getVal(billTotalList.fourthList.first)+getUnit(billTotalList.fourthList.first) }}</span> -->
              </div>
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.fourthList.secondtName }}</span>
                <span class="unit">{{ billTotalList.fourthList.secondtName == null?'':billTotalList.fourthList.second == null?0:billTotalList.fourthList.second }}</span>
                <!-- <span class="unit">{{ billTotalList.fourthList.secondtName == null?'':billTotalList.fourthList.second == null?0:getVal(billTotalList.fourthList.second)+getUnit(billTotalList.fourthList.second) }}</span> -->
              </div>
            </div>
          </div>
          <div class="items flex-space-between">
            <img class="bottom-img" :src="bottomImg(billTotalList.fourthList.type)" alt="">
          </div>
        </div>
        <div :class="borderColor(billTotalList.fifthList.type)">
          <div class="display-card-item">
            <div class="card-item-title flex-space-around">
              <!-- <div class="title-icon-red">
                <icon-font class="icon-color" :type="billTotalList.fifthList.icon" :class="iconColor(billTotalList.fifthList.type)" />
              </div> -->
              <div class="title-text">{{ billTotalList.fifthList.totalName }}</div>
            </div>
            <div class="text-content">
              <!-- <span class="number">{{ billTotalList.fifthList.total == null?'0':getVal(billTotalList.fifthList.total) }}</span> -->
              <span class="number">{{ billTotalList.fifthList.total == null||billTotalList.fifthList.total == NaN?'0':billTotalList.fifthList.total }}</span>
              <!-- <span class="unit">{{ billTotalList.fifthList.unit }}</span> -->
            </div>
            <div class="align-items-center">
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.fifthList.firstName }}</span>
                <span class="unit">{{ billTotalList.fifthList.firstName == null?'':billTotalList.fifthList.first == null?0:billTotalList.fifthList.first }}</span>
                <!-- <span class="unit">{{ billTotalList.fifthList.firstName == null?'':billTotalList.fifthList.first == null?0:getVal(billTotalList.fifthList.first)+getUnit(billTotalList.fifthList.first) }}</span> -->
              </div>
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.fifthList.secondtName }}</span>
                <span class="unit">{{ billTotalList.fifthList.secondtName == null?'':billTotalList.fifthList.second == null?0:billTotalList.fifthList.second }}</span>
                <!-- <span class="unit">{{ billTotalList.fifthList.secondtName == null?'':billTotalList.fifthList.second == null?0:getVal(billTotalList.fifthList.second)+getUnit(billTotalList.fifthList.second) }}</span> -->
              </div>
            </div>
          </div>
          <div class="items flex-space-between">
            <img class="bottom-img" :src="bottomImg(billTotalList.fifthList.type)" alt="">
          </div>
        </div>
        <div :class="borderColor(billTotalList.sixthList.type)">
          <div class="display-card-item">
            <div class="card-item-title flex-space-around">
              <!-- <div class="title-icon">
                <icon-font class="icon-color" :type="billTotalList.sixthList.icon" :class="iconColor(billTotalList.sixthList.type)" />
              </div> -->
              <div class="title-text">{{ billTotalList.sixthList.totalName }}</div>
            </div>
            <div class="text-content">
              <!-- <span class="number">{{ billTotalList.sixthList.total == null?'0':getVal(billTotalList.sixthList.total) }}</span> -->
              <span class="number">{{ billTotalList.sixthList.total == null?'0':billTotalList.sixthList.total }}</span>
              <!-- <span class="unit">{{ getUnit(billTotalList.sixthList.total)+billTotalList.sixthList.unit }}</span> -->
            </div>
            <div class="align-items-center">
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.sixthList.firstName }}</span>
                <span class="unit">{{ billTotalList.sixthList.firstName == null?'':billTotalList.sixthList.first == null?0:getVal(billTotalList.sixthList.first)+getUnit(billTotalList.sixthList.first) }}</span>
              </div>
              <div class="items flex-space-between">
                <span class="items-name">{{ billTotalList.sixthList.secondtName }}</span>
                <span class="unit">{{ billTotalList.sixthList.secondtName == null?'':billTotalList.sixthList.second == null?0:getVal(billTotalList.sixthList.second)+getUnit(billTotalList.sixthList.second) }}</span>
              </div>
            </div>
          </div>
          <div class="items flex-space-between">
            <img class="bottom-img" :src="bottomImg(billTotalList.sixthList.type)" alt="">
          </div>
        </div>

      </div>
    </div>
    
    <div class="echarts-box">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="display-card-echarts">
            <div class="title-box flex-space-between">
              <div class="title-text">语音线路统计</div>
              <div class="flex-space-between">
                <div class="selector-box-other">
                  <el-select v-model="value0" placeholder="请选择" size="mini" @change="changeline()">
                    <el-option
                      v-for="(item, index) in interfaceList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="selector-box">
                  <el-select v-model="value1" placeholder="请选择" size="mini" @change="changeValue(1)">
                    <el-option
                      v-for="item in [{
                            label: '周',
                            value: 0
                        },{
                            label: '月',
                            value: 1
                        }]"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div>
              <div id="myChart1" :style="{ width: '90%', height: '380px' }"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="display-card-echarts">
            <div class="title-box flex-space-between">
              <div class="title-text">转化相关数据环比</div>
              <div class="selector-box">
                <el-select v-model="value2" placeholder="请选择" size="mini" @change="changeValue(2)">
                  <el-option
                    v-for="item in [{
                          label: '周',
                          value: 0
                      },{
                          label: '月',
                          value: 1
                      }]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex-space-around">
              <div id="myChart2" :style="{ width: '40%', height: '380px' }"></div>
              <div id="myChart5" :style="{ width: '40%', height: '380px' }"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="display-card-echarts">
            <div class="title-box flex-space-between">
              <!-- <div class="title-text">黑名单统计</div> -->
              <div class="title-text flex-space-between relative-box">
                <div>黑名单统计</div>
                <div>
                  <icon-font class="icon-help" type="icon-bangzhu1"/>
                  <div class="absolute-box">
                    <div class="triangle"></div>
                    <div class="flex-space-between other-out1" v-for="(help,h) in helpLeftList" :key = 'h'>
                      <div class="font-help width-init-1">{{ help.name }}</div>
                      <div class="font-help-new width-init-2">{{ help.annotation }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="selector-box">
                <el-select v-model="value3" placeholder="请选择" size="mini" @change="changeValue(3)">
                  <el-option
                    v-for="item in [{
                          label: '周',
                          value: 0
                      },{
                          label: '月',
                          value: 1
                      }]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <div id="myChart3" :style="{ width: '90%', height: '380px' }"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="display-card-echarts">
            <div class="title-box flex-space-between">
              <!-- <div class="title-text">语音质检统计</div> -->
              <div class="title-text flex-space-between relative-box">
                <div>语音质检统计</div>
                <div>
                  <icon-font class="icon-help" type="icon-bangzhu1"/>
                  <div class="absolute-box">
                    <div class="triangle"></div>
                    <div class="flex-start-nowrap other-out2" v-for="(help,h) in helpRightList" :key = 'h'>
                      <div class="font-help width-init-1">{{ help.name }}</div>
                      <div class="font-help-new width-init-2">{{ help.annotation }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="selector-box">
                <el-select v-model="value4" placeholder="请选择" size="mini" @change="changeValue(4)">
                  <el-option
                    v-for="item in [{
                          label: '周',
                          value: 0
                      },{
                          label: '月',
                          value: 1
                      }]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <div id="myChart4" :style="{ width: '90%', height: '380px' }"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import dashboard from "@/api/dashboard/dashboard";
// import interfacets from "@/api/open/privacy/interface";
import amounts from "@/api/open/privacy/amount";
// import blackCount from "@/api/open/black/blackCount";
import { IconFont } from "@/utils/iconfont"
export default {
    components: {
        IconFont,
    },
    data() {
      return {
        helpTopList:[//顶部说明帮助
          {
            name:'拨打次数',
            annotation:'拨打电话次数'
          },{
            name:'接通次数',
            annotation:'拨打成功接通次数'
          },{
            name:'接通率',
            annotation:'拨打成功接通的概率'
          },{
            name:'总通话时长',
            annotation:'总的通电话的时间'
          },{
            name:'通话费',
            annotation:'拨打电话产生的费用'
          },{
            name:'累计充值',
            annotation:'总的充值金额'
          },
        ],
        helpLeftList:[//顶部说明帮助
          {
            name:'拦截次数',
            annotation:'开放黑名单功能，并购买套餐，每 次拨打对比黑名单，对比成功后进行拦截。'
          },{
            name:'API调用次数',
            annotation:'开放接口，每次请求查询，用户通过接 口传输需要查询的号码，对比黑名单， 并反馈查询结果。'
          }
        ],
        helpRightList:[//顶部说明帮助
          {
            name:'质检次数 ',
            annotation:'用户使用语音线路呼叫并存在录音，开 通质检功能后，每检测一次，质检次数累加。'
          },{
            name:'API调用次数',
            annotation:'用户使用API调用语音识别接口，每调用一 次，质检次数累加。'
          }
        ],
        options:[
          {
            label: '全部线路',
            value: 1
          }
        ],
        value0:'',
        value1:0,
        value2:0,
        value3:0,
        value4:0,

        timer: null,  // 定时器
        interfaceList:[],
        billTotalList:{
          firstList:{
            icon:'icon-copy-4-12',
            type:0,
            unit:'次',
            totalName:'拨打次数',
            total:null,
            firstName:null,
            first:null,
            secondtName:'本月拨打次数',
            second:null,
            thirdName:null,
            third:null
          },
          secondList:{
            icon:'icon-copy-4-25',
            type:0,
            unit:'次',
            totalName:'接通次数',
            total:null,
            firstName:null,
            first:null,
            secondtName:'本月接通次数',
            second:null,
            thirdName:null,
            third:null
          },
          thirdList:{
            icon:'icon-chart-pie-alt',
            type:1,
            unit:'',
            totalName:'接通率',
            total:null,
            firstName:null,
            first:null,
            secondtName:'本月接通率',
            second:null,
            thirdName:null,
            third:null
          },
          fourthList:{
            icon:'icon-a-zu4',
            type:0,
            unit:'分钟',
            totalName:'总通话时长',
            total:null,
            firstName:null,
            first:null,
            secondtName:'本月通话时长',
            second:null,
            thirdName:null,
            third:null
          },
          fifthList:{
            icon:'icon-primary',
            type:2,
            unit:'元',
            totalName:'通话费',
            total:null,
            firstName:null,
            first:null,
            secondtName:'本月通话费',
            second:null,
            thirdName:null,
            third:null
          },
          sixthList:{
            icon:'icon-chongzhi1',
            type:0,
            unit:'元',
            totalName:'累计充值',
            total:null,
            firstName:null,
            first:null,
            secondtName:null,
            second:null,
            thirdName:null,
            third:null
          }
        },
        // 折线图
        myChart1xAxis:[],
        myChart1series1:[],
        myChart1series2:[],
        // 柱图1
        myChart3xAxis:[],
        myChart3series1:[],
        myChart3series2:[],
        // 柱图2
        myChart4xAxis:[],
        myChart4series1:[],
        myChart4series2:[],
        // 环比1
        myChart2data:{},
        type:'期',
        // 环比2
        myChart5data:{},
        time1:[],
        time2:[],
        time3:[],
        countType:0,
      }
    },
    beforeUnmount() {
      clearInterval(this.timer);        
      this.timer = null;
      // console.log('销毁计时器')
    },
    mounted(){
      if(localStorage.getItem('type') == 1){
        localStorage.setItem('type','0')
        location.reload()
      }
      this.getApiType()
      // 清除原画布
      echarts.init(document.getElementById("myChart1")).dispose()
      echarts.init(document.getElementById("myChart2")).dispose()
      echarts.init(document.getElementById("myChart3")).dispose()
      echarts.init(document.getElementById("myChart4")).dispose()
      echarts.init(document.getElementById("myChart5")).dispose()
      // 获取画布元素
      let myChart1 = echarts.init(document.getElementById("myChart1"));
      let myChart2= echarts.init(document.getElementById("myChart2"));
      let myChart3= echarts.init(document.getElementById("myChart3"));
      let myChart4 = echarts.init(document.getElementById("myChart4"));
      let myChart5 = echarts.init(document.getElementById("myChart5"));
      // 数据请求绘图操作
      this.time = this.fun_date(-6)
      this.getBillTotal()//头部统计卡
      this.getCallStatistic(myChart1,this.time)//折线图
      this.getBlackStatistics(myChart3,this.time)//柱图1
      this.getVoiceStatistics(myChart4,this.time)//柱图2
      this.getCallCompletingRate(myChart2,this.countType)//环比1
      this.getConsumeRate(myChart5,this.countType)//环比2
      // 定时刷新
      let that = this
      this.timer = setInterval(function(){
        that.getBillTotal()//头部统计卡
        that.getCallStatistic(myChart1,that.time1,that.value0)//折线图
        that.getBlackStatistics(myChart3,that.time2)//柱图1
        that.getVoiceStatistics(myChart4,that.time3)//柱图2
        that.getCallCompletingRate(myChart2,that.countType)//环比1
        that.getConsumeRate(myChart5,that.countType)//环比2
        // console.log('开启计时器')
      },15000)
      // 图标自适应
      window.addEventListener("resize", (() => {
          myChart1.resize()
          myChart2.resize()
          myChart3.resize()
          myChart4.resize()
          myChart5.resize()
      }));
    },
    computed: {
        
    },
    methods: {
      enters(data){
        // console.log(data)
      },
      getApiType() {
        // interfacets.seeApiList({}).then((res) => {
        //   this.interfaceList = res.data;
        //   this.interfaceList.unshift({
        //     id:'',
        //     name:'全部线路'
        //   })
        // });
        amounts.getAmountList({}).then((res) => {
          console.log(res);
          this.interfaceList = res.data;
          this.interfaceList.unshift({
            id:'',
            name:'全部服务商'
          })
        });
      },
      getVal(value) {
        var val;
        if(value > 1 && value < 10000){
          val = value;
        }else{
          val = value>9999?(value/10000).toFixed(2):value;  // 保留两位小数
        }
        return val;
      },
      getUnit(value) {
        var val;
        if(value < 10000){
          val = '';
        }else if(value == null){
          val = '';
        }else{
          val = '万';  // 保留两位小数
        }
        return val;
      },
      changeline(){
        if(this.value1 == 0){
          this.time1 = this.fun_date(-6)
        }
        if(this.value1 == 1){
          this.time1 = this.fun_date(-30)
        }
        // echarts.init(document.getElementById("myChart1")).dispose()
        let myChart1 = echarts.init(document.getElementById("myChart1"));
        this.getCallStatistic(myChart1,this.time1,this.value0)//折线图
      },
      changeValue(type){
        if(type == 1){
          if(this.value1 == 0){
            this.time1 = this.fun_date(-6)
          }
          if(this.value1 == 1){
            this.time1 = this.fun_date(-30)
          }
          // echarts.init(document.getElementById("myChart1")).dispose()
          let myChart1 = echarts.init(document.getElementById("myChart1"));
          this.getCallStatistic(myChart1,this.time1)//折线图
        }
        if(type == 2){
          if(this.value2 == 0){
            // this.time = this.fun_date(-6)
            this.countType = 0 
            this.type = '期'          }
          if(this.value2 == 1){
            // this.time = this.fun_date(-30)
            this.countType = 1
            this.type = '期'   
          }
          // echarts.init(document.getElementById("myChart2")).dispose()
          // echarts.init(document.getElementById("myChart5")).dispose()
          let myChart2= echarts.init(document.getElementById("myChart2"));
          let myChart5 = echarts.init(document.getElementById("myChart5"));
          this.getCallCompletingRate(myChart2,this.countType)//环比1
          this.getConsumeRate(myChart5,this.countType)//环比2
        }
        if(type == 3){
          if(this.value3 == 0){
            this.time2 = this.fun_date(-6)
          }
          if(this.value3 == 1){
            this.time2 = this.fun_date(-30)
          }
          // echarts.init(document.getElementById("myChart3")).dispose()
          let myChart3= echarts.init(document.getElementById("myChart3"));
          this.getBlackStatistics(myChart3,this.time2)//柱图1
        }
        if(type == 4){
          if(this.value4 == 0){
            this.time3 = this.fun_date(-6)
          }
          if(this.value4 == 1){
            this.time3 = this.fun_date(-30)
          }
          // echarts.init(document.getElementById("myChart4")).dispose()
          let myChart4 = echarts.init(document.getElementById("myChart4"));
          this.getVoiceStatistics(myChart4,this.time3)//柱图2
        }
      },
      fun_date(aa){
        var date1 = new Date(),
        time1=date1.getFullYear()+"-"+(date1.getMonth()+1)+"-"+date1.getDate();//time1表示当前时间
        var date2 = new Date(date1);
        date2.setDate(date1.getDate()+aa);
        var time2 = date2.getFullYear()+"-"+(date2.getMonth()+1)+"-"+date2.getDate();
        return [time2,time1];
      },
      // 边框颜色
      borderColor(type){
        let colorList = {
          0:'bule-border-bottom display-card-border',
          1:'green-border-bottom display-card-border',
          2:'red-border-bottom display-card-border'
        }
        return colorList[type]
      },
      iconColor(type){
        let colorList = {
          0:'icon-bule',
          1:'icon-green',
          2:'icon-red'
        }
        return colorList[type]
      },
      bottomImg(type){
        let imgUrl = {
          0:require(`@/assets/images/blue.png`),
          1:require(`@/assets/images/green.png`),
          2:require(`@/assets/images/red.png`)
        }
        return imgUrl[type]
      },
      // 折线图数据接口
      getCallStatistic(myChart,time,interfaceId){
        dashboard.callStatistics({
          dateBegin:time[0],
          dateEnd:time[1],
          amountId:interfaceId
        }).then((res) => {
          console.log(res);
          this.myChart1xAxis = []
          this.myChart1series1 = []//接通次数
          this.myChart1series2 = []//拨打次数
          res.data.forEach((item,i)=>{
            this.myChart1xAxis.push(item.date)
            this.myChart1series1.push(item.callNum)
            this.myChart1series2.push(item.billNum)
          })
          this.getCountBillTotal(myChart)
        }).catch({

        })
      },
      // 柱图1数据接口
      getBlackStatistics(myChart,time){
        dashboard.blackStatistics({
          dateBegin:time[0],
          dateEnd:time[1]
        }).then((res) => {
          this.myChart3xAxis = []
          this.myChart3series1 = []
          this.myChart3series2 = []
          res.data.forEach((item,i)=>{
            this.myChart3xAxis.push(item.date)
            this.myChart3series1.push(item.useCount)
            this.myChart3series2.push(item.queryCount)
          })
          this.drawChart3(myChart)
        }).catch({
          
        })
      },
      // 柱图2数据接口
      getVoiceStatistics(myChart,time){
        dashboard.voiceStatistics({
          dateBegin:time[0],
          dateEnd:time[1]
        }).then((res) => {
          this.myChart4xAxis = []
          this.myChart4series1 = []
          this.myChart4series2 = []
          res.data.forEach((item,i)=>{
            this.myChart4xAxis.push(item.date)
            this.myChart4series1.push(item.checkTotal)
            this.myChart4series2.push(item.recognitionTotal)
          })
          this.drawChart4(myChart)
        }).catch({
          
        })
      },
      // 环比图1
      getCallCompletingRate(myChart,countType){
        dashboard.callCompletingRate({
          countType:countType
        }).then((res) => {
          this.myChart2data = {}
          this.myChart2data = res.data
          this.drawChart2(myChart)
        }).catch({
          
        })
      },
      // 环比图2
      getConsumeRate(myChart,countType){
        dashboard.consumeRate({
          countType:countType
        }).then((res) => {
          this.myChart5data = {}
          this.myChart5data = res.data
          this.drawChart5(myChart)
        })
      },
      // 顶部展示卡数据请求
      getBillTotal(){
        // this.billTotalList = []
        let newBillList = []
        dashboard.billTotal({}).then((res) => {
          this.billTotalList.firstList.total = res.data.billNum.todayTotal
          // this.billTotalList.firstList.first = res.data.billNum.monthTotal
          // this.billTotalList.firstList.second = res.data.billNum.total
          this.billTotalList.firstList.second = res.data.billNum.monthTotal

          this.billTotalList.secondList.total = res.data.callNum.todayTotal
          // this.billTotalList.secondList.first = res.data.callNum.monthTotal
          // this.billTotalList.secondList.second = res.data.callNum.total
          this.billTotalList.secondList.second = res.data.callNum.monthTotal
          
          this.billTotalList.thirdList.total = res.data.percentage.todayTotal
          // this.billTotalList.thirdList.first = res.data.percentage.monthTotal
          // this.billTotalList.thirdList.second = res.data.percentage.total
          this.billTotalList.thirdList.second = res.data.percentage.monthTotal
          
          this.billTotalList.fourthList.total = res.data.callTime.todayTotal
          // this.billTotalList.fourthList.first = res.data.callTime.monthTotal
          // this.billTotalList.fourthList.second = res.data.callTime.total
          this.billTotalList.fourthList.second = res.data.callTime.monthTotal

          if(res.data.callCharge.todayTotal != null){
            this.billTotalList.fifthList.total = parseFloat(res.data.callCharge.todayTotal).toFixed(2)
          }else{
            this.billTotalList.fifthList.total = 0
          }
          if(res.data.callCharge.monthTotal != null){
            this.billTotalList.fifthList.first = parseFloat(res.data.callCharge.monthTotal).toFixed(2)
          }else{
            this.billTotalList.fifthList.first = 0
          }
          if(res.data.callCharge.monthTotal != null){
            this.billTotalList.fifthList.second = parseFloat(res.data.callCharge.monthTotal).toFixed(2)
          }else{
            this.billTotalList.fifthList.second = 0
          }
          
          dashboard.recharge({}).then((res) => {
            this.billTotalList.sixthList.total = res.data.tradeDetailTotal.dealAmount
          }).catch({
          
          })
        }).catch({
              
        })
      },
      // 取百分比
      GetPercent(num, total) {
        num = parseFloat(num);
        total = parseFloat(total);
        if (isNaN(num) || isNaN(total)) {
            return "-";
        }
        return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00);
      },
      // 绘制折线图
      getCountBillTotal(myChart){
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              lineStyle:{
                width:2,
                color: '#637DFF',
                type:'solid'  //'dotted'虚线 'solid'实线
              }
            }
          },
          title : {
              // text: '呼叫统计',
              // x:'left',
              // y: 'top',
          },
          legend: {
              data: this.nameList,
              // orient: 'vertical',
              x: 'center',
          },
          xAxis: {
              data: this.myChart1xAxis
          },
          yAxis: {
              // type: 'category',
              // axisLine : {onZero: true}
          },
          yAxis:{},
          series: [
            {
              name: '拨打次数',
              type: "line",
              data: this.myChart1series2,
              // markPoint: {
              //     data: [
              //         {type: 'max', name: '最大值'},
              //         {type: 'min', name: '最小值'}
              //     ],
              // },
              smooth: true,
              itemStyle : {  
                  normal : {  
                      color: '#FFB463',  
                      lineStyle:{  
                          color: '#FFB463',
                          width: 2 
                      }  
                  }  
              },  
            },
            {
              name: '接通次数',
              type: "line",
              data: this.myChart1series1,
              // markPoint: {
              //     data: [
              //         {type: 'max', name: '最大值'},
              //         {type: 'min', name: '最小值'}
              //     ],
              // },
              smooth: true,
              itemStyle : {  
                  normal : {  
                      color: '#637DFF',  
                      lineStyle:{  
                          color: '#637DFF',
                          width: 2 
                      }  
                  }  
              },  
            }
          ]
        })
      },
      // 绘制柱图(左下)
      drawChart3(myChart){
        myChart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'shadow'
                }
            },
            legend: {},
            xAxis: [
                {
                type: 'category',
                data: this.myChart3xAxis
                }
            ],
            yAxis: [
                {
                type: 'value'
                }
            ],
            series: [
              {
                name: '拦截次数',
                type: 'bar',
                stack: 'Ad',
                barWidth: 40,
                itemStyle: {
                    normal: {
                        color: '#FFB463',
                        // label: {
                        //     show: true,
                        //     position: 'top',
                        //     formatter: '{b}\n{c}'
                        // }
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: this.myChart3series1
                },
                {
                name: 'API调用次数',
                type: 'bar',
                stack: 'Ad',
                barWidth: 40,
                itemStyle: {
                    normal: {
                      color: '#637DFF',
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: this.myChart3series2
              },
            ]
        })
      },
      // 绘制柱图(右下)
      drawChart4(myChart){
        myChart.setOption({
          color: ['#02C395', '#FFB463'],
          tooltip: {
              trigger: 'axis',
              axisPointer: {
              type: 'cross'
              }
          },
          // grid: {
          //     right: '20%'
          // },
          toolbox: {
              // feature: {
              // dataView: { show: true, readOnly: false },
              // restore: { show: true },
              // saveAsImage: { show: true }
              // }
          },
          legend: {
              data: ['质检次数', 'API调用次数', ]
          },
          xAxis: [
              {
              type: 'category',
              axisTick: {
                  alignWithLabel: true
              },
              // prettier-ignore
              data: this.myChart4xAxis
              }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          // yAxis: [
          //   {
          //     type: 'value',
          //     name: '质检次数',
          //     // min: 0,
          //     // max: 250,
          //     position: 'left',
          //     axisLine: {
          //         show: true,
          //         lineStyle: {
          //         color: '#02C395'
          //         }
          //     },
          //     axisLabel: {
          //         formatter: '{value} '
          //     }
          //   },
          //   {
          //     type: 'value',
          //     name: 'API调用次数',
          //     // min: 0,
          //     // max: 250,
          //     position: 'right',
          //     offset: 80,
          //     axisLine: {
          //         show: true,
          //         lineStyle: {
          //         color: '#FFB463'
          //         }
          //     },
          //     axisLabel: {
          //         formatter: '{value} '
          //     }
          //   },
          // ],
          series: [
              {
              name: '质检次数',
              type: 'bar',
              data: this.myChart4series1
              },
              {
              name: 'API调用次数',
              type: 'bar',
              // yAxisIndex: 1,
              data: this.myChart4series2
              },
          ]
        })
      },
      // 绘制环比图(左一)
      drawChart2(myChart){
            let value1 = parseInt(this.myChart2data.previousCallNum/this.myChart2data.previousBillNum*100)
            let value2 = parseInt(this.myChart2data.currentCallNum/this.myChart2data.currentBillNum*100)
            myChart.setOption({
              color: ['#FFB463', '#02C395'],
              title : {
                text:this.myChart2data.growth,
                x:'center',
                y: '150', 
                subtext:'环比上期',
                itemGap: 20,
                textStyle: {
                  fontSize: 18,
                  color:'#333333',
                  fontStyle: 'normal',
                  fontWeight: 'bolder',
                },
                subtextStyle: {
                  fontSize: 12,
                  color:'#666666',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                },
              },
              series: [
                  {
                      type: 'gauge',
                      startAngle: 90,
                      endAngle: -270,
                      pointer: {
                          show: false
                      },
                      progress: {
                          show: true,
                          overlap: false,
                          roundCap: true,
                          clip: false,
                          itemStyle: {
                          borderWidth: 4,
                          borderColor: '#fff'
                          }
                      },
                      axisLine: {
                          lineStyle: {
                          width: 35
                          }
                      },
                      splitLine: {
                          show: false,
                          distance: 0,
                          length: 10
                      },
                      axisTick: {
                          show: false
                      },
                      axisLabel: {
                          show: false,
                          distance: 50
                      },
                      data: [
                          {
                              value: value1,
                              name: '上'+this.type,
                              title: {
                                color: '#FFB463',
                                offsetCenter: ['30%', '130%']
                              },
                              detail: {
                                show: false
                              }
                          },
                          {
                              value: value2,
                              name: '本'+this.type,
                              title: {
                                color: '#02C395',
                                offsetCenter: ['-30%', '130%']
                              },
                              detail: {
                                // show: false
                                offsetCenter: ['0%', '30%']
                              }
                          }
                      ],
                      title: {
                        fontSize: 14,
                        color: 'auto',
                        fontWeight: 'bolder',
                      },
                      detail: {
                        width: 50,
                        height: 14,
                        fontSize: 14,
                        color: '#333333',
                        formatter: '接通率环比',
                        fontWeight: 'bolder',
                      }
                  }
              ]
          })
      },
      // 绘制环比图(右一)
      drawChart5(myChart){
        myChart.setOption({
            color: ['#01C3ED', '#0170ED'],
            title : {
              text:this.myChart5data.growth,
              x:'center',
              y: '150', 
              subtext:'环比上期',
              itemGap: 20,
              textStyle: {
                fontSize: 18,
                color:'#333333',
                fontStyle: 'normal',
                fontWeight: 'bolder',
              },
              subtextStyle: {
                fontSize: 12,
                color:'#666666',
                fontStyle: 'normal',
                fontWeight: 'normal',
              },
            },
            series: [
                {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 4,
                        borderColor: '#fff'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 35
                        }
                    },
                    splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: [
                      {
                          value: this.myChart5data.previousTotal,
                          name: '上'+this.type,
                          title: {
                            color: '#01C3ED',
                            offsetCenter: ['30%', '130%']
                          },
                          detail: {
                            show: false
                          }
                      },
                      {
                          value: this.myChart5data.currentTotal,
                          name: '本'+this.type,
                          title: {
                            color: '#0170ED',
                            offsetCenter: ['-30%', '130%']
                          },
                          detail: {
                            offsetCenter: ['0%', '30%']
                          }
                      }
                    ],
                    title: {
                      fontSize: 14,
                      color: 'auto',
                      fontWeight: 'bolder',
                    },
                    detail: {
                      width: 50,
                      height: 14,
                      fontSize: 14,
                      color: '#333333',
                      formatter: '消费环比',
                      fontWeight: 'bolder',
                    }
                }
            ]
        })
      }
    }
}
</script>

<style lang="scss" scoped>
.outLib {
  height: 100%;
  overflow-y: overlay;
  margin: 0.9375rem;
  padding: 5px 18px 5px 5px;
}
.top-box {
  // margin: 0.9375rem;
  padding: 0.9375rem;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0px 0px 6px 0px #d3d6e2;
}
// .other-box{
//     margin: .9375rem;
// }
.title-text {
  font-size: 0.875rem;
  font-weight: 700;
}
.selector-box {
  margin-left: 1rem;
  width: 6.25rem;
}
.selector-box-other {
  margin-left: 1rem;
  width: 9rem;
}
.display-card {
  margin-top: 0.9375rem;
}
.display-card-item {
  width: 14rem;
  height: 10.5rem;
  padding: 0.9375rem;
}
.display-card-border{
  border-radius: 1em 1em 0 0;
  background: #fff;
  box-shadow: 0px 0px 6px 0px #d3d6e2; 
}
.bottom-img{
  width: 100%;
}
.echarts-box{
  margin-top: 0.9375rem;
}
.display-card-echarts {
  width: 100%;
  height: 430px;
  padding: 0.9375rem;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0px 0px 6px 0px #d3d6e2;
}
.bule-border-bottom {
  border-bottom: 4px solid #637dff;
}
.red-border-bottom {
  border-bottom: 4px solid #FF6363;
}
.green-border-bottom {
  border-bottom: 4px solid #02C395;
}
.icon-bule{
  color: #637dff;
}
.icon-red{
  color: #FF6363;
}
.icon-green{
  color: #02C395;
}
.title-icon {
  margin-right: 0.5rem;
  border-radius: 6px;
  background: #F0F3FF;
}
.title-icon-red {
  margin-right: 0.5rem;
  border-radius: 6px;
  background: #FFF3F3;
}
.title-icon-green {
  margin-right: 0.5rem;
  border-radius: 6px;
  background: #F0FFFC;
}
.icon-color {
  font-size: .75rem;
  margin: 4px;
}
.text-content {
  // margin-left: 1.5625rem;
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1rem;
}
.number {
  font-size: 1.625rem;
  color: #323543;
  font-weight: 700;
}
.unit {
  font-size: .75rem;
  color: #323543;
  font-weight: 700;
}
.items-name {
  font-weight: 600;
}
.items {
  height: 1.375rem;
}
.icon-help{
  margin-left: .75rem;
  font-size: .875rem;
  color: #858585;
  cursor: pointer;
}
.relative-box{
  position: relative;
  .absolute-box{
    display: none;
    z-index: 10;
    position: absolute;
    padding: 16px;
    top: 38px;
    left: 50px;
    border-radius: .5rem;
    opacity: 0.75;
    background: #000000;
    box-shadow: 0px 3px 28px 0px rgba(0,0,0,0.10); 
  }
}
.relative-box:hover .absolute-box{
  display: block;
  z-index: 10;
  position: absolute;
  padding: 16px;
  top: 38px;
  left: 50px;
  border-radius: .5rem;
  opacity: 0.75;
  background: #000000;
  box-shadow: 0px 3px 28px 0px rgba(0,0,0,0.10); 
}
.font-help{
  font-size: 14px;
  font-weight: 400;
  color: #63FFFF;
}
.font-help-new{
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.other-out0{
  .width-init-1{
    width: 120px;
    // margin-right: 30px;
    height: 26px;
    line-height: 26px;
    text-align: left;
  }
  .width-init-2{
    width: 200px;
  }
}
.other-out1{
  .width-init-1{
    width: 100px;
    // margin-right: 30px;
    height: 26px;
    line-height: 26px;
    text-align: left;
  }
  .width-init-2{
    width: 290px;
  }
}
.other-out2{
  .width-init-1{
    width: 100px;
    // margin-right: 30px;
    height: 26px;
    line-height: 26px;
    text-align: left;
  }
  .width-init-2{
    width: 290px;
  }
}
.flex-start-nowrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.flex-space-around {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.flex-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.flex-start-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
// 三角形
.triangle{
  position: absolute;
  top: -12px;
  left: 15px;
  border-style: solid;
  border-width: 0px 8px 12px 8px;
  opacity: 1.52;
// background: #000000;
  border-color: transparent transparent #000000 transparent;
  width: 0px;
  height: 0px;
}
</style>
