// 仪表盘
import { Request } from '@/http/request'

// 总数据统计
export function billTotal (parameter: any) {
  return Request.axiosInstance.post('/web/dashboard/bill-total', parameter)
}

// 充值金额展示
export function recharge() {
  return Request.axiosInstance.post('/web/dashboard/recharge')
}

// 上月消费额统计
export function lastMonthConsume(parameter: any) {
  return Request.axiosInstance.post('/web/dashboard/last-month-consume', parameter)
}

// 本月消费额统计
export function thisMonthConsume(parameter: any) {
  return Request.axiosInstance.post('/web/dashboard/this-month-consume', parameter)
}

// 折线 ​
export function callStatistics(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-count/call-statistics', parameter)
}

// 黑名单 ​
export function blackStatistics(parameter: any) {
  return Request.axiosInstance.post('/open/black-count/black-statistics', parameter)
}

// 语音柱图 ​
export function voiceStatistics(parameter: any) {
  return Request.axiosInstance.post('/open/voice-count/voice-statistics', parameter)
}

// 隐私号环比 ​
export function callCompletingRate(parameter: any) {
  return Request.axiosInstance.post('/open/user-number-count/call-completing-rate', parameter)
}

// 消费环比 ​
export function consumeRate(parameter: any) {
  return Request.axiosInstance.post('/web/dashboard/consume-rate', parameter)
}
export function balanceSum(parameter: any){
  return Request.axiosInstance.post('/web/trade-detail/balance-sum',parameter)
}

export default { billTotal, recharge, lastMonthConsume, thisMonthConsume, callStatistics, blackStatistics, voiceStatistics, callCompletingRate, consumeRate,balanceSum }